import * as VueRouter from 'vue-router'
import Home from './pages/home/person_home'


const Main = () => import('./pages/main/main_index.vue');
const Manage = () => import('./pages/study/manage_system.vue');
const routes = [
    { 
      path: '/',
      component: Home,
      // redirect:'/main',
      children:[
        { path: '/main', component: Main },
      ]
    },
    { path: '/manage', component: Manage },
    
  ]


const router = VueRouter.createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: VueRouter.createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})
  
export default router