<template>
    <div class="menu">
        <a-menu @click="onTitleClick" :selectedKeys="current" mode="horizontal" :items="items" />
        <div class="part">
            <div class="description">
              <h2 class="description-title">
                  &nbsp;
              </h2>
              <div class="description-content" v-html="teacher_message?.teacher?.[current*1]?.content">
              </div>
          </div>
        </div>
    </div>
</template>

<script setup>
import {h, ref, defineProps, reactive, watch} from 'vue'
import { AppstoreOutlined } from '@ant-design/icons-vue';
// import { useRouter } from 'vue-router'


const props = defineProps({  
      // 声明 props  
    teacher_message: {  
        type:Object,
        required: true, // 是否必需  
    }  
})  

let teacher_message = reactive(props.teacher_message);
let items = teacher_message.teacher?.map((item,index)=>{
  return {
    key: index+'',
    icon: () => h(AppstoreOutlined),
    label: item.moduleName,
    title: item.moduleName,
  }
}) || []

watch(()=>props.teacher_message,()=>{
    teacher_message = reactive(props.teacher_message);
    items = teacher_message.teacher?.map((item,index)=>{
    return {
        key: index+'',
        icon: () => h(AppstoreOutlined),
        label: item.moduleName,
        title: item.moduleName,
    }
    }) || []
})




// const router = useRouter()
const current = ref(['0']);
// const items = ref([
//   {
//     key: 'main',
//     icon: () => h(MailOutlined),
//     label: '主页',
//     title: '主页',
//   },
//   {
//     key: 'study',
//     icon: () => h(AppstoreOutlined),
//     label: '研究情况',
//     title: '研究情况',
//   },
//   {
//     key: 'alipay',
//     icon: () => h(AppstoreOutlined),
//     // label: h(
//     //   'a',
//     //   {
//     //     href: 'https://antdv.com',
//     //     target: '_blank',
//     //   },
//     //   '研究室成员',
//     // ),
//     label: '研究室成员',
//     title: '研究室成员',
//   },

//   //   {
//   //   key: 'sub1',
//   //   icon: () => h(SettingOutlined),
//   //   label: '其它',
//   //   title: '其它',
//   //   children: [
//   //     {
//   //       type: 'group',
//   //       label: 'Item 1',
//   //       children: [
//   //         {
//   //           label: 'Option 1',
//   //           key: 'setting:1',
//   //         },
//   //         {
//   //           label: 'Option 2',
//   //           key: 'setting:2',
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       type: 'group',
//   //       label: 'Item 2',
//   //       children: [
//   //         {
//   //           label: 'Option 3',
//   //           key: 'setting:3',
//   //         },
//   //         {
//   //           label: 'Option 4',
//   //           key: 'setting:4',
//   //         },
//   //       ],
//   //     },
//   //   ],
//   // },
// ]);
const onTitleClick = (message) => {
    current.value = [message.key]
    // router.push(`/${message.key}`)
    // if(message.key==='main'){
    //     router.push('/main')
    // }
}


</script>

<style scoped lang="less">
.menu{
    // color:#527bbd;
      color: #2c3e50;
}

::v-deep(.ant-menu-item){
    font-size: 20px;
    font-weight: 700;
}
::v-deep(.ant-menu){
    display: flex;
    justify-content: center;
    //  color:#527bbd;
}

::v-deep(.ant-menu-title-content){
    font-size: 20px;
    font-weight: 700;

}
::v-deep(.ant-menu-item){
    margin: 0 44px;
}

.description{
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    white-space:pre-wrap;
    &-title{
        text-align: center;
    }
    &-content{
        line-height: 1.5;
    }
}

.part{
    width: 60vw;
    min-width: 750px;
    margin: 0 auto;
}
</style>