<template>
    <div class="mainBlock">
        <div v-if="!!teacher_message?.data?.teacher">
            <div class="isEn">
                Language: &nbsp;&nbsp;
                <a-select
                    ref="select"
                    v-model:value="isEn"
                    style="width: 120px"
                    @change="changeEn"
        
                    >
                    <a-select-option value="CH">中文</a-select-option>
                    <a-select-option value="EN">English</a-select-option>
                </a-select>
            </div>

            <homeHeader :teacher_message="teacher_message.data"></homeHeader>

            <menus  :teacher_message="teacher_message.data"></menus>
        </div>
        <router-view :teacher_message="teacher_message.data" class="part"></router-view>
        <div style="height:80px"></div>
        <div class="beianBlock">
            <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">ICP备案号：</a>
            <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2024039753号</a>
        </div>
    </div>


</template>

<script setup>
// import {reactive} from 'vue'
import homeHeader from './components/home_header.vue'
import menus from './components/home_menu.vue'
// import teacherMessage from '../../message.json'
// const teacher_message = reactive({...teacherMessage})
import axios from 'axios'
import { reactive,ref } from 'vue'



const isEn = ref('CH');
const teacher_message = reactive({
    data:{}
})
axios.post('http://122.152.237.45:30000/jsonHandle/get',{isEn:isEn.value})
.then(res=>{
    teacher_message.data = res.data.data
})

const changeEn = ()=>{
    axios.post('http://122.152.237.45:30000/jsonHandle/get',{isEn:isEn.value})
    .then(res=>{
        teacher_message.data = res.data.data
    })
}

</script>

<style scoped>
.mainBlock{
    min-height: 100vh;
    position: relative;
}
.part{
    width: 60vw;
    min-width: 750px;
    margin: 0 auto;
}
.isEn{
    position: absolute;
    right: 120px;
    top:20px;
}
.beian{
    text-decoration: none;
    text-decoration-line: none;
    color: gray;
}
.beianBlock{
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%,0);
}
</style>