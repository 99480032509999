<template>
    <div class="headerLine">
        <img class="headerLine-img" :src="teacher_message.image" alt="">
        <div class="headerLine-desc">
            <div class="headerLine-desc-line" style="font-weight:800; font-size:30px;" v-html="teacher_message.name"></div>
            <div class="headerLine-desc-line" v-html="teacher_message.description"></div>
            <div class="headerLine-desc-line" v-html="teacher_message.email"></div>
        </div>
    </div>

</template>

<script setup>
import {defineProps, reactive, watch} from 'vue'
const props = defineProps({  
      // 声明 props  
    teacher_message: {  
        required: true, // 是否必需  
    }  
})  

let teacher_message = reactive(props.teacher_message)
watch(()=>props.teacher_message,()=>{
    teacher_message = reactive(props.teacher_message);
})
</script>

<style scoped lang="less">
.headerLine{
    font-size: 22px;
    min-height: 240px;
    width:100vw;
    min-width: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(82,122,192,0.2),#fff);
    color:#527bbd;
    &-img{
        border-radius: 50%;
        width: 140px;
        height: 140px;
        margin: 0 60px 0 0;
    }
    &-desc{
        &-line{
            line-height: 1.5;
            text-align: left;
            white-space:pre-wrap;
        }
    }
}
</style>